import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import logo from "../images/tikidigital-logo.svg"
import "../style.css"

const IndexPage = () => (
  <Layout>
    <div className="container p-8 mx-auto text-center">
      <SEO title="" />
      <header className="mt-16 mb-12">
        <h1 className="sr-only">Tiki Digital LLC</h1>
        <img src={logo} className="max-w-full md:max-w-xl mx-auto mt-2 mb-2" />
        <p className="text-2xl font-hand">Established 2014 🌴</p>
      </header>
      <section>
        <h2 className="text-3xl">Modern Node-Powered Development</h2>
        <p className="text-2xl">Gatsby, NestJS, Node.js, React</p>
        <p className="font-italic p-16 mb-12">Client portal coming soon.</p>
      </section>
      {/* <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
    </div>
  </Layout>
)

export default IndexPage
